










































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import BankForm from "@/accounting-portal/components/banks/BankForm.vue";
import BankResponseModel from "@/accounting-portal/models/banks/BankResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    BankForm,
  },
})
export default class BanksView extends mixins(AppSectionAccessMixin) {
  search = "";
  isSideBarVisible = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get headers(): Array<Record<string, any>> {
    return [
      {
        text: this.$lang("accountingPortal.banks.name"),
        value: "name",
      },
      {
        text: this.$lang("accountingPortal.banks.code"),
        value: "code",
      },
      {
        text: this.$lang("accountingPortal.address.title"),
        value: "address",
      },
    ];
  }

  get loading(): boolean {
    return this.$store.state.bankStore.isBanksLoading;
  }

  get banks(): Array<BankResponseModel> {
    return this.$store.state.bankStore.banks;
  }

  get isBanksPage(): boolean {
    return this.$route.name === AppSection.BANKS;
  }

  isBankSelected({ id }: BankResponseModel): boolean {
    return id === Number(this.$route.params.bankId);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("accountingPortal.banks.title")
    );

    this.$store.dispatch("loadBanks");
  }

  destroyed() {
    this.$store.commit("clearDictionaries");
  }

  handleClickByTableRow(bank: BankResponseModel) {
    if (this.isBankSelected(bank)) {
      return;
    }

    this.$router.push({
      name: "bank",
      params: {
        bankId: String(bank.id),
      },
    });
  }
}
